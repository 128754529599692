<template>
      <div>
            <b-card no-body class="mb-0">
                  <div class="m-2">
                        <b-row>
                              <b-col cols="12" md="3" class="">
                                    <label>Mostrar</label>
                                    <v-select v-model="tableSettings.perPage" :options="perPageOptions" :clearable="false"
                                          class="per-page-selector d-inline-block mx-50" />
                                    <label>registros</label>
                              </b-col>
                        </b-row>
                  </div>
                  <b-table :parametritation="tableSettings.parametritation" ref="refRoleListTable" class="position-relative"
                        :items="dataTable" responsive :fields="tableColumns" primary-key="id"
                        :sort-by.sync="tableSettings.sortBy" show-empty empty-text="No se encontraron datos"
                        :sort-desc.sync="tableSettings.sortDesc">
                        <template #cell(role)="data">
                              <div v-for="(item, index) in data.item.role" :key="index">
                                    <span>{{ item }}</span>
                              </div>
                        </template>
                        <!-- Column: Actions -->
                        <template #cell(actions)="data">
                              <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">

                                    <template #button-content>
                                          <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
                                    </template>
                                    <b-dropdown-item  @click="orders(data.item.id)">
                                          <feather-icon icon="CheckIcon"/>
                                          <span class="align-middle ml-50">Pedidos</span>
                                    </b-dropdown-item>
                              </b-dropdown>
                        </template>

                  </b-table>
                  <div class="mx-2 mb-2">
                        <b-row>

                              <b-col cols="12" sm="6"
                                    class="d-flex align-items-center justify-content-center justify-content-sm-start">
                                    <span class="text-muted">Viendo del {{ dataMeta.from }} al {{ dataMeta.to }} de {{
                                          dataMeta.of }} registros</span>
                              </b-col>
                              <!-- Pagination -->
                              <b-col cols="12" sm="6"
                                    class="d-flex align-items-center justify-content-center justify-content-sm-end">

                                    <b-pagination v-model="tableSettings.page" :total-rows="totalRows"
                                          :per-page="tableSettings.perPage" first-number last-number class="mb-0 mt-1 mt-sm-0"
                                          prev-class="prev-item" next-class="next-item">
                                          <template #prev-text>
                                                <feather-icon icon="ChevronLeftIcon" size="18" />
                                          </template>
                                          <template #next-text>
                                                <feather-icon icon="ChevronRightIcon" size="18" />
                                          </template>
                                    </b-pagination>

                              </b-col>

                        </b-row>
                  </div>

            </b-card>

      </div>
</template>
    
<script>
import { BContainer, BRow, BCol, BCard, BButton, BTable, BPagination, BDropdown, BDropdownItem } from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import router from '@/router';
export default {
      name: 'Parametritations',
      components: { BContainer, BRow, BCol, BCard, BButton, BTable, BPagination, vSelect, BDropdown, BDropdownItem },
      data() {
            return {
                  userData: JSON.parse(localStorage.getItem('userData')),
                  formDisabled: false,
                  isAddNewSidebarActive: false,
                  refRoleListTable: null,
                  perPageOptions: [10, 25, 50, 100],
                  searchQuery: '',
                  inputs: [
                        { value: 'id', title: 'Id' },
                        { value: 'name', title: 'Nombre' },
                        { value: 'email', title: 'Correo' },
                        { value: 'email', title: 'Correo' },
                  ],
                  typeSearch: [
                        { value: 'LIKE', title: 'Igual' },
                        { value: 'NOT LIKE', title: 'No es igual' },
                        { value: '>', title: 'Mayor que' },
                        { value: '<', title: 'Menor que' }
                  ],

                  tableColumns: [
                        { key: 'name', label: 'Nombre', sortable: true },
                        { key: 'deparment', label: 'Ciudad', sortable: true },
                        { key: 'group', label: 'Grupo', sortable: true },
                        { key: 'document', label: 'Documento', sortable: true },
                        { key: 'actions', label: 'acciones' },
                  ],
                  sortBy: 'customer_id',
                  isSortDirDesc: true,
                  totalRows: 0,
                  dataMeta: {
                        from: 0,
                        to: 0,
                        of: 0,
                  },
                  dataTable: [],
                  tableSettings: {
                        filter: this.$route.params && this.$route.params.id,
                        searchQuery: '',
                        input: '',
                        typeSearch: '',
                        perPage: 10,
                        page: 1,
                        sortBy: 'customer_id',
                        sortDesc: true,
                        parametritation: false
                  },
                  customersOptions: [],
            }
      },
      methods: {
            orders(id) {
                this.$router.push('/admin/orders/' + id)
            },
            createParametritation(id) {
                  this.$router.push('/admin/createParametritation/' + id)
            },
            async getTableData() {
                  this.tableSettings.parametritation = true;
                  await this.$http.get('/list-customers/' + this.userData.id, { params: this.tableSettings }).then((response) => {
                        this.dataTable = response.data.cutomers
                        this.totalRows = response.data.total
                        this.dataMetaCounter()
                  })
                  this.tableSettings.parametritation = false;
            },
            dataMetaCounter() {
                  const localItemsCount = this.dataTable.length
                  this.dataMeta.from = this.tableSettings.perPage * (this.tableSettings.page - 1) + (localItemsCount ? 1 : 0)
                  this.dataMeta.to = this.tableSettings.perPage * (this.tableSettings.page - 1) + localItemsCount
                  this.dataMeta.of = this.totalRows
            },
            async fetchCustomers() {
                  await this.$http.get('/users/customers')
                        .then((response) => {
                              this.customersOptions = response.data.customersOptions
                        })
            },
      },
      created() {
            this.getTableData();
            this.fetchCustomers();
      },
      directives: {
            Ripple,
      },
      watch: {
            "tableSettings.sortBy": {
                  handler(val) {
                        this.getTableData()
                  },
            },
            "tableSettings.sortDesc": {
                  handler(val) {
                        this.getTableData()
                  },
            },
            "tableSettings.perPage": {
                  handler(val) {
                        this.getTableData()
                  },
            },
            "tableSettings.searchQuery": {
                  handler(val) {
                        this.getTableData()
                  },
            },
            "tableSettings.page": {
                  handler(val) {
                        this.getTableData()
                  },
            },
      }
}
</script>
    
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>